import React from 'react'

const Contact = () => {
  return (
    <div>
      <div>
         Linkendin
         Github
      </div>
    </div>
  )
}

export default Contact